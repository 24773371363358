import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxSpinnerModule } from "ngx-spinner";
import { NotFoundComponent } from './views/not-found/not-found.component';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';
import {MatCardModule} from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormComponent } from './views/form/form.component';
import {  HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import { FacPopupComponent } from './views/fac-popup/fac-popup.component';
import { AppConfirmService } from './services/app-confirm/app-confirm.service';
import { MatDialogModule } from "@angular/material/dialog";
import { ValidatorComponent } from './validator/validator.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    UnauthorizedComponent,
    FormComponent,
    FacPopupComponent,
    ValidatorComponent
  ],
  imports: [
    MatCardModule,
    BrowserModule,
    NgxSpinnerModule,
    MatDividerModule,
    FlexLayoutModule,
    MatDialogModule,
    HttpClientModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [AppConfirmService],
  bootstrap: [AppComponent]
})
export class AppModule { }
