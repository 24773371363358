<div class="container">
    <h1>Validación de Pago</h1>
    <div *ngIf="status">
      <p>Estado del Pago: {{ status }}</p>
      <p>ID de la Transacción: {{ transactionId }}</p>
      <p>Monto: {{ amount }} {{ currency }}</p>
      <p>Código de Respuesta: {{ responseCode }}</p>
      <p>Mensaje de Respuesta: {{ responseMessage }}</p>
    </div>
    <p *ngIf="!status">Esperando respuesta del banco...</p>
  </div>
  
  