import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CallService } from 'src/app/services/call.service';
declare var jQuery: any;

@Component({
  selector: 'app-fac-popup',
  templateUrl: './fac-popup.component.html',
  styleUrls: ['./fac-popup.component.css']
})
export class FacPopupComponent implements OnInit {

  responseForm : any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private call: CallService,
    public dialogRef: MatDialogRef<FacPopupComponent>,
  ) { }

  ngOnInit(): void {
    this.responseForm = this.data.form
  }

  exit() {
    // this.call.get(this.call.HOST + "/creditcard/" + this.data.process).subscribe(process =>{
    //   if(process.result.verified){
    //     this.dialogRef.close(process.result.verified);
    //   } 
    // }, err =>{
    //   this.dialogRef.close();
    // })

  }

}
