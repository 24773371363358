import { ChangeDetectorRef, Component, OnInit, ElementRef, ViewChild, ComponentFactoryResolver, ViewContainerRef, AfterViewInit, Renderer2, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CallService } from 'src/app/services/call.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from '../../../environments/environment';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FacPopupComponent } from '../fac-popup/fac-popup.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConfirmService } from 'src/app/services/app-confirm/app-confirm.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap, delay,takeWhile  } from 'rxjs/operators';
import { interval, Observable, of, timer,Subject } from 'rxjs';
import { UniqueCodeService } from '../../services/unique-code.service';
import { DOCUMENT } from '@angular/common';

declare var jQuery: any;

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit, AfterViewInit {
  private pollingSuccess = new Subject<void>();
  private iframeElement: HTMLIFrameElement;
  @ViewChild('iframe', { static: false }) iframe: ElementRef;
  @ViewChild('myFormPost') myFormPost: ElementRef;
  basicForm: FormGroup;
  public HOST: string = environment.backendUrl;
  signedID: any; // ID DE SIGNED URL - INFORMACION DE CLIENTE ENVIADA EN JSON
  token: any; // TOKEN DE SEGURIDAD
  cc: any; // ID DEL TOKEN EN NUESTRA PLATAFORMA
  signature: any; // FIRMA GENERADA PARA ENVIAR
  merchantUrl: any; // URL A LA QUE HAY QUE ENVIAR LA RESPUESTA
  tokenTdc: any; // TOKEN DE LA TDC QUE SE GENERO
  reference: any; // REFERENCIA ENVIADA POR EL CLIENTE PARA EL PROCESO
  plan: any; // ID DEL PLAN AL QUE SE ESTA SUSCRIBIENDO EL CLIENTE
  jsonEncoded: any; // JSON QUE SE ENVIARA AL CLIENTE
  signatureCalculated: any; // FIRMA QUE SE GENERA EN BASE AL JSON
  uniqueCode: string;
  jwtTokenForThird: string; // Añadir esta línea
  transactionIdForThird: string; // Añadir esta línea

  // BACKENDURL = "http://localhost:8080/";
  BACKENDURL = "https://devsop.billcentric.com/";
  clientForm: FormGroup;
  myIp: String;
  myHeight: any;
  myWidth: any;
  invoiceId: any;

  first: FormGroup;

  constructor(private formBuilder: FormBuilder, private vcRef: ViewContainerRef, private resolver: ComponentFactoryResolver, private http: HttpClient, private cdr: ChangeDetectorRef, public confirmService: AppConfirmService, private call: CallService, private sanitizer: DomSanitizer, private dialog: MatDialog, private activatedRoute: ActivatedRoute, private spinner: SpinnerService, private uniqueCodeService: UniqueCodeService, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) {
    this.basicForm = new FormGroup({
      numberCreditCard: new FormControl('', [Validators.required]),
      nameHolderCreditCard: new FormControl('', Validators.required),
      expMonthCreditCard: new FormControl('', Validators.required),
      expYearCreditCard: new FormControl('', Validators.required),
      ccv: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),
      email : new FormControl('')
    });

    this.clientForm = new FormGroup({
      Ds_SignatureVersion: new FormControl('HMAC_SHA256_V1'),
      Ds_MerchantParameters: new FormControl('', Validators.required),
      Ds_Signature: new FormControl('', Validators.required)
    });

    this.first = this.formBuilder.group({
      JWT: ''
    });

    this.invoiceId = Math.floor(Math.random() * 100000);
  }

  addUniqueCodeToHead(uniqueCode: string) {
    const comment = this.renderer.createComment('script head nico');
    this.renderer.appendChild(this.document.head, comment);
    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'type', 'text/javascript');
    this.renderer.setAttribute(script, 'src', `https://h.online-metrix.net/fp/tags.js?org_id=1snn5n9w&session_id=bg_avocadoprime${uniqueCode}`);
    this.renderer.appendChild(this.document.head, script);
    // Check if the script loaded correctly
    script.onload = () => {
      console.log('Script loaded successfully.');
    };

    script.onerror = () => {
      console.error('Failed to load the script.');
    };
  }

  addUniqueCodeToBody(uniqueCode: string) {
    const noscript = this.renderer.createElement('noscript');
    const iframe = this.renderer.createElement('iframe');
    this.renderer.setStyle(iframe, 'width', '100px');
    this.renderer.setStyle(iframe, 'height', '100px');
    this.renderer.setStyle(iframe, 'border', '0');
    this.renderer.setStyle(iframe, 'position', 'absolute');
    this.renderer.setStyle(iframe, 'top', '-5000px');
    this.renderer.setAttribute(iframe, 'src', `https://h.online-metrix.net/fp/tags?org_id=1snn5n9w&session_id=bg_avocadoprime${uniqueCode}`);
    this.renderer.appendChild(noscript, iframe);
    this.renderer.appendChild(this.document.body, noscript);
  }

  ngOnInit(): void {
    this.spinner.open();

    this.http.get('https://api.ipify.org/?format=json').subscribe(response => {
      console.log(response);
      this.myIp = response['ip'];
      this.myHeight = window.innerHeight;
      this.myWidth = window.innerWidth;
      this.activatedRoute.queryParams.subscribe(querys => {
        this.token = querys.token;
        this.activatedRoute.params.subscribe(param => {
          this.signedID = param.id;
          this.spinner.close();
        });
      }, err => {
        console.log(err);
      });
    });
    this.pollingSuccess.subscribe(() => {
      this.continueToNextStep();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const uniqueCode = this.generateUniqueCode();
      this.uniqueCodeService.setUniqueCode(uniqueCode);
      this.uniqueCode = uniqueCode;
      console.log('Código único generado al cargar la página:', uniqueCode);
      this.addUniqueCodeToHead(uniqueCode); // Añadir el uniqueCode al head
      this.addUniqueCodeToBody(uniqueCode); // Añadir el uniqueCode al body
      this.cdr.detectChanges();
    }, 0);
  }

  createTransactionData(transactionIdentifier: string = '', additionalData: any = {}) {
    return {
      terminal: "bgeneral_hecticus",
      invoice: {
        invoiceId: this.invoiceId,
        invoiceTotal: 0.7
      },
      creditCard: {
        creditCard: this.basicForm.value.numberCreditCard.trim(),
        cardholderName: this.basicForm.value.nameHolderCreditCard,
        customerId: 15507,
        creditCardDate: this.basicForm.value.expYearCreditCard + this.basicForm.value.expMonthCreditCard,
        cvv: this.basicForm.value.ccv
      },
      transactionIdentifier: transactionIdentifier,
      currency: "USD",
      extraData: {
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        cardType: this.basicForm.value.type,
        screenWidth: this.myWidth,
        email : this.basicForm.value.email == '' ? null : this.basicForm.value.email,
        returnUrl: additionalData.returnUrl,
        ...additionalData
      },
      auth: additionalData.auth
    };
  }

  transactionenrollment(transactionIdentifier: string = '', additionalData: any = {}) {
    return {
      terminal: "bgeneral_hecticus",
      invoice: {
        invoiceId: this.invoiceId,
        invoiceTotal: 0.7
      },
      creditCard: {
        creditCard: this.basicForm.value.numberCreditCard.trim(),
        cardholderName: this.basicForm.value.nameHolderCreditCard,
        customerId: 15507,
        creditCardDate: this.basicForm.value.expYearCreditCard + this.basicForm.value.expMonthCreditCard,
        cvv: this.basicForm.value.ccv
      },
      transactionIdentifier: transactionIdentifier,
      currency: "USD",
      extraData: {
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        cardType: this.basicForm.value.type,
        screenWidth: this.myWidth,
        email : this.basicForm.value.email,
        ...additionalData
      },
      auth: additionalData.auth
    };
  }

  // console.log('respuesta para ENROLLEMNT :', secondResponse);
  // if (secondResponse.responseCodeDescription == "AUTHENTICATION_SUCCESSFUL"){
    
  //   if(secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "Y"){
  //     console.log("Successful Frictionless Authentication");
  //     console.log("veresEnrolled y paresStatus son = 'Y' ESTO CUBRE EL TEST PARA 2.1 DE LA DOCUMENTACION")
  //   } else if(secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "A"){
  //     console.log("Attempts Processing Frictionless Authentication");
  //     console.log("TEST 2.3 DE LA DOCUMENTACION")
  //   } else if(secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "U"){
  //     console.log("Unavailable Frictionless Authentication");
  //     console.log("TEST 2.4 DE LA DOCUMENTACION")
  //   } else if(secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "R"){
  //     console.log("Rejected Frictionless Authentication");
  //     console.log("TEST 2.5 DE LA DOCUMENTACION")
  //   } else if(secondResponse.extraData.veresEnrolled == "U"){
  //     console.log("PUEDE SER")
  //     console.log("Authentication not Available on Lookup");
  //     console.log("TEST 2.6 DE LA DOCUMENTACION")
  //     console.log("O tambien")
  //     console.log("Authentication not Available on Lookup");
  //     console.log("TEST 2.7 DE LA DOCUMENTACION")
  //     console.log("O tambien")
  //     console.log("Time-Out");
  //     console.log("TEST 2.8 DE LA DOCUMENTACION")
  //     console.log("YA QUE TODOS TIENEN EL MISMO PARAMETRO veresEnrolled == 'U'")
  //   } else if(secondResponse.extraData.veresEnrolled == "B"){
  //     console.log("Bypassed Authentication");
  //     console.log("TEST 2.9 DE LA DOCUMENTACION")
  //   }else{
  //     console.log("ENROLLMENTE NO RESTONO NINGINO DE LOS veresEnrolled y paresStatus PREDETERMINADOS");
  //   }

  //   const fourthData = this.createTransactionData(secondResponse.transactionId, {
  //     auth: {
  //       authenticationTransactionId: secondResponse.transactionId,
  //       signedPares: secondResponse.threeDS.token
  //     },
  //     eci : secondResponse.extraData.eci,
  //     eciRaw : secondResponse.extraData.eciRaw,
  //     cavv : secondResponse.extraData.cavv,
  //     paresStatus : secondResponse.extraData.paresStatus,
  //     veresEnrolled : secondResponse.extraData.veresEnrolled,
  //     xid : secondResponse.extraData.xid
  //   });
  //   console.log('REQUEST DIRECTO PORQUE ES FRICTION LESS', fourthData);
  //   this.call.post(fourthData, this.BACKENDURL + "Sale").subscribe(fourthResponse =>{
  //     console.log('respuesta del SALE :', fourthResponse);
  //     this.spinner.close();
  //   });
  //   return of(null);
  // }else if(secondResponse.responseCodeDescription == "AUTHENTICATION_FAILED"){
  //   console.log("Unsuccessful Frictionless Authentication");
  //   console.log("AUTHENTICATION_FAILED NO SE HACE NADA ESTO CUBRE EL TEST 2.2");
  //   return of(null);
  // }else if(secondResponse.responseCodeDescription == "PENDING_AUTHENTICATION") {
  //   console.log('SE REALIZA PROCESO DE 3DS YA QUE ENROLLMENT RETORNO  PENDING_AUTHENTICATION');
  //   this.jwtTokenForThird = secondResponse.threeDS.htmlCode; // Almacenar el jwtTokenForThird aquí
  //   this.transactionIdForThird = secondResponse.transactionId; // Almacenar el transactionId aquí
  //   console.log('htmlCode para el 3DS:', this.jwtTokenForThird);

  //   if(secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "C"){
  //     console.log("Successful Step-Up Authentication");
  //     console.log("TEST 2.10 DE LA DOCUMENTACION")
  //     console.log("TAMBIEN PUEDE SER")
  //     console.log("TEST 2.11 DE LA DOCUMENTACION")
  //     console.log("TAMBIEN PUEDE SER")
  //     console.log("TEST 2.12 DE LA DOCUMENTACION")
  //     console.log("YA QUE TIENEN RESPONSES IGUALES")
  //     console.log("veresEnrolled == 'Y' && paresStatus == 'C'")
  //   }

  //   this.createAndSubmitStepUpForm(this.jwtTokenForThird);
  //   return of(null);
  // }else{
  //   console.log("REVISAR O CONSULTAR YA QUE ENROLLMENT NO RETORNO NINGUNO DE LOS ESTATUS PREDETERMINADOS");
  //   return of(null);
  // }

  send() {
    console.log('Código único utilizado en send():', this.uniqueCode);
    this.spinner.open();
    this.invoiceId = Math.floor(Math.random() * 100000);
  
    const data = this.createTransactionData();
    console.log("SE SOLICITA PAYER AUTHENTICATION");
    console.log("REQUEST: ", data);
    console.log(this.BACKENDURL + "PayerAuth");
    this.call.post(data, this.BACKENDURL + "PayerAuth")
      .pipe(
        switchMap(response => this.handlePayerAuthResponse(response)),
        switchMap(secondResponse => this.handleEnrollmentResponse(secondResponse)),
        catchError(error => this.handleError(error))
      )
      .subscribe(thirdResponse => {
        console.log('Tercera respuesta:', thirdResponse);
        this.spinner.close();
      });
  }
  
  private handlePayerAuthResponse(response: any): Observable<any> {
    console.log('respuesta del PAYER AUTHENTICATION:', response);
    if (response.responseCodeDescription != "COMPLETED") {
      console.log("ERROR - PAYER AUTH DISTINTO DE COMPLETE");
      return of(null);
    }
    const transactionIdentifier = response.transactionId;
    const jwtToken = response.threeDS.token;
    this.uniqueCodeService.setTransactionIdentifier(transactionIdentifier);
  
    this.createAndSubmitIframeForm(jwtToken, "https://centinelapistag.cardinalcommerce.com/V1/Cruise/Collect", "ddc-iframe", "ddc-form");
  
    return timer(5000).pipe(
      switchMap(() => {
        const updatedData = this.transactionenrollment(transactionIdentifier);
        console.log('REQUEST PARA ENROLLMENT :', updatedData);
        return this.call.post(updatedData, this.BACKENDURL + "EnrollmentAuth");
      })
    );
  }
  
  private handleEnrollmentResponse(secondResponse: any): Observable<any> {
    console.log('respuesta para ENROLLMENT :', secondResponse);
    if (secondResponse.responseCodeDescription == "AUTHENTICATION_SUCCESSFUL") {
      this.handleEnrollmentSuccess(secondResponse);
      return of(null);
    } else if (secondResponse.responseCodeDescription == "AUTHENTICATION_FAILED") {
      console.log("Unsuccessful Frictionless Authentication");
      console.log("AUTHENTICATION_FAILED NO SE HACE NADA ESTO CUBRE EL TEST 2.2");
      return of(null);
    } else if (secondResponse.responseCodeDescription == "PENDING_AUTHENTICATION") {
      this.handlePendingAuthentication(secondResponse);
      return of(null);
    } else {
      console.log("REVISAR O CONSULTAR YA QUE ENROLLMENT NO RETORNO NINGUNO DE LOS ESTATUS PREDETERMINADOS");
      return of(null);
    }
  }
  
  private handleEnrollmentSuccess(secondResponse: any) {
    if (secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "Y") {
      console.log("Successful Frictionless Authentication");
      console.log("veresEnrolled y paresStatus son = 'Y' ESTO CUBRE EL TEST PARA 2.1 DE LA DOCUMENTACION");
    } else if (secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "A") {
      console.log("Attempts Processing Frictionless Authentication");
      console.log("TEST 2.3 DE LA DOCUMENTACION");
    } else if (secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "U") {
      console.log("Unavailable Frictionless Authentication");
      console.log("TEST 2.4 DE LA DOCUMENTACION");
    } else if (secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "R") {
      console.log("Rejected Frictionless Authentication");
      console.log("TEST 2.5 DE LA DOCUMENTACION");
    } else if (secondResponse.extraData.veresEnrolled == "U") {
      console.log("Authentication not Available on Lookup");
      console.log("TEST 2.6 DE LA DOCUMENTACION");
      console.log("O tambien")
      console.log("Authentication not Available on Lookup");
      console.log("TEST 2.7 DE LA DOCUMENTACION")
      console.log("O tambien")
      console.log("Time-Out");
      console.log("TEST 2.8 DE LA DOCUMENTACION")
      console.log("YA QUE TODOS TIENEN EL MISMO PARAMETRO veresEnrolled == 'U'")
    } else if (secondResponse.extraData.veresEnrolled == "B") {
      console.log("Bypassed Authentication");
      console.log("TEST 2.9 DE LA DOCUMENTACION");
    } else {
      console.log("ENROLLMENT NO RETORNO NINGUNO DE LOS veresEnrolled y paresStatus PREDETERMINADOS");
    }
  
    const fourthData = this.createTransactionData(secondResponse.transactionId, {
      auth: {
        authenticationTransactionId: secondResponse.transactionId
        // signedPares: secondResponse.threeDS.token
      },
      eci: secondResponse.extraData.eci,
      eciRaw: secondResponse.extraData.eciRaw,
      cavv: secondResponse.extraData.cavv,
      paresStatus: secondResponse.extraData.paresStatus,
      veresEnrolled: secondResponse.extraData.veresEnrolled,
      xid: secondResponse.extraData.xid,
      authenticationTransactionId : secondResponse.extraData.authenticationTransactionId,
      acsTransactionId : secondResponse.extraData.acsTransactionId,
      ecommerceIndicator : secondResponse.extraData.ecommerceIndicator,
      specificationVersion : secondResponse.extraData.specificationVersion,
      directoryServerTransactionId : secondResponse.extraData.directoryServerTransactionId,
      ucafAuthenticationData : secondResponse.extraData.ucafAuthenticationData,
      ucafCollectionIndicator : secondResponse.extraData.ucafCollectionIndicator
    });
    console.log('REQUEST DIRECTO PORQUE ES FRICTION LESS', fourthData);
    this.call.post(fourthData, this.BACKENDURL + "Sale").subscribe(fourthResponse => {
      console.log('respuesta del SALE :', fourthResponse);
      this.spinner.close();
    });
  }
  
  private handlePendingAuthentication(secondResponse: any) {
    console.log('SE REALIZA PROCESO DE 3DS YA QUE ENROLLMENT RETORNO PENDING_AUTHENTICATION');
  
    const redirectUrl = secondResponse.threeDS.redirectUrl;
    console.log('Redirect URL:', redirectUrl);
  
    const match = redirectUrl.match(/authorize3DS\/(\d+)\//);
    const extractedNumber = match ? match[1] : null;
  
    if (extractedNumber) {
      console.log('Número extraído:', extractedNumber);
      this.startPolling(extractedNumber);
    } else {
      console.error('No se pudo extraer el número de la URL');
    }
  
    this.jwtTokenForThird = secondResponse.threeDS.htmlCode;
    this.transactionIdForThird = secondResponse.transactionId;
    console.log('htmlCode para el 3DS:', this.jwtTokenForThird);
  
    if (secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "C") {
      console.log("Successful Step-Up Authentication");
      console.log("TEST 2.10 DE LA DOCUMENTACION");
    }
  
    this.createAndSubmitStepUpForm(this.jwtTokenForThird);
  }
  
  private handleError(error: any): Observable<null> {
    console.error('Error en la cadena de peticiones:', error);
    this.spinner.close();
    return of(null);
  }
  

  createAndSubmitIframeForm(jwtToken: string, actionUrl: string, iframeName: string, formId: string): void {
    const iframe = document.createElement('iframe');
    iframe.name = iframeName;
    iframe.height = '900';
    iframe.width = '900';
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    this.iframeElement = iframe; // Guardar referencia al iframe
  
    const form = document.createElement('form');
    form.id = formId;
    form.target = iframeName;
    form.method = 'POST';
    form.action = actionUrl;
  
    const inputJWT = document.createElement('input');
    inputJWT.type = 'hidden';
    inputJWT.name = 'JWT';
    inputJWT.value = jwtToken;
    form.appendChild(inputJWT);
  
    document.body.appendChild(form);
    form.submit();
  
    window.addEventListener("message", (event) => {
      if (event.origin === "https://centinelapistag.cardinalcommerce.com") {
        console.log(event);
        let data = JSON.parse(event.data);
        console.log('Merchant received a message:', data);
      }
    }, false);
  }
  
  createAndSubmitStepUpForm(jwtToken: string) {
    const iframe = document.createElement('iframe');
    iframe.name = 'step-up-iframe';
    iframe.height = '900';
    iframe.width = '900';
    iframe.style.display = 'block';
    document.body.appendChild(iframe);
    this.iframeElement = iframe; // Guardar referencia al iframe
  
    const form = document.createElement('form');
    form.id = 'step-up-form';
    form.target = 'step-up-iframe';
    form.method = 'post';
    form.action = 'https://centinelapistag.cardinalcommerce.com/V2/Cruise/StepUp';
  
    const inputJWT = document.createElement('input');
    inputJWT.type = 'hidden';
    inputJWT.name = 'JWT';
    inputJWT.value = jwtToken;
    form.appendChild(inputJWT);
  
    const inputMD = document.createElement('input');
    inputMD.type = 'hidden';
    inputMD.name = 'MD';
    inputMD.value = 'optionally_include_custom_data_that_will_be_returned_as_is';
    form.appendChild(inputMD);
  
    document.body.appendChild(form);
    form.submit();
  
    window.addEventListener("message", (event) => {
      if (event.origin === "https://centinelapistag.cardinalcommerce.com") {
        console.log(event);
        let data = JSON.parse(event.data);
        console.log('Merchant received a message:', data);
      }
    }, false);
  }

  continueToNextStep() {
    this.spinner.open();

    const transactionIdentifier = this.uniqueCodeService.getTransactionIdentifier();

    if (!this.jwtTokenForThird || !this.transactionIdForThird) {
      console.error('No se encontró jwtTokenForThird o transactionIdForThird para la tercera petición.');
      this.spinner.close();
      return;
    }

    const thirdData = this.createTransactionData(transactionIdentifier, {
      auth: {
        authenticationTransactionId: this.transactionIdForThird,
        signedPares: this.jwtTokenForThird
      }
    });

    console.log('REQUEST PARA VALIDATE AUTH ', thirdData);
    this.call.post(thirdData, this.BACKENDURL + "ValidateAuth")
      .pipe(
        switchMap(thirdResponse => {
          console.log('respuesta para VALIDATE AUTH:', thirdResponse);

          if (thirdResponse.responseCodeDescription == "AUTHENTICATION_SUCCESSFUL") {

            if (thirdResponse.extraData.paresStatus == "Y") {
              console.log("Successful Step-Up Authentication ON VALIDATE AUTH");
              console.log("TEST 2.10 DE LA DOCUMENTACION");
            } else if (thirdResponse.extraData.paresStatus == "U") {
              console.log("Unavailable Step-Up Authentication");
              console.log("TEST 2.12 DE LA DOCUMENTACION");
            } else {
              console.log("paresStatus NO ENTRA DENTRO DE NINGUNA DE LAS COMPARACIONES PREDETERMINADAS");
            }

            const fourthData = this.createTransactionData(transactionIdentifier, {
              auth: {
                authenticationTransactionId: this.transactionIdForThird
                // signedPares: this.jwtTokenForThird
              },
              eci: thirdResponse.extraData.eci,
              eciRaw: thirdResponse.extraData.eciRaw,
              cavv: thirdResponse.extraData.cavv,
              authenticationTransactionId : thirdResponse.extraData.authenticationTransactionId,
              acsTransactionId : thirdResponse.extraData.acsTransactionId,
              paresStatus: thirdResponse.extraData.paresStatus,
              veresEnrolled: thirdResponse.extraData.veresEnrolled,
              xid: thirdResponse.extraData.xid,
              ecommerceIndicator : thirdResponse.extraData.ecommerceIndicator,
              specificationVersion : thirdResponse.extraData.specificationVersion,
              directoryServerTransactionId : thirdResponse.extraData.directoryServerTransactionId,
              ucafAuthenticationData : thirdResponse.extraData.ucafAuthenticationData,
              ucafCollectionIndicator : thirdResponse.extraData.ucafCollectionIndicator
            });

            console.log('REQUEST PARA SALE', fourthData);
            return this.call.post(fourthData, this.BACKENDURL + "Sale");
          } else {
            console.log("Unsuccessful Step-Up Authentication");
            console.log("TEST 2.11 DE LA DOCUMENTACION");
            console.log("VALIDATE AUTH RETORNO AUTHENTICATION_FAILED");
            return of(null)
          }

        }),
        catchError(error => {
          console.error('Error en la cadena de peticiones:', error);
          this.spinner.close();
          return of(null);
        })
      )
      .subscribe(fourthResponse => {
        console.log('respuesta del SALE :', fourthResponse);
        this.spinner.close();
      });
  }

  startPolling(extractedNumber: string) {
    const url = `https://dev.hecbill.hecticus.com/audition/info/invoice/${extractedNumber}`;
    let polling = true;
  
    interval(3000).pipe(
      takeWhile(() => polling), // Mantener el polling mientras la condición sea verdadera
      switchMap(() => this.http.get(url, { observe: 'response' }).pipe(
        catchError(error => {
          if (error.status === 404) {
            console.log('Respuesta 404: No encontrado');
            return of(null);
          } else {
            console.error('Error en la solicitud:', error);
            return of(null);
          }
        })
      ))
    ).subscribe(response => {
      if (response && response.status === 200) {
        console.log('Respuesta 200: OK');
        console.log('Datos:', response.body);
        polling = false; // Detener el polling
        this.pollingSuccess.next(); // Emitir evento de éxito
        this.removeIframe(); // Eliminar el iframe
      }
    });
  }
  
  private removeIframe() {
    if (this.iframeElement) {
      document.body.removeChild(this.iframeElement);
      this.iframeElement = null;
    }
  }
  
  

  myLoadEvent() {
    let json: any = {};
    json.reference = this.reference;
    json.plan = this.plan;

    this.call.get(this.call.HOST + "/signedUrl/" + this.signedID).subscribe(signed => {

      this.jsonEncoded = signed.result.jsonEncoded;
      this.signatureCalculated = signed.result.signatureCalculated;
      this.clientForm.controls['Ds_MerchantParameters'].setValue(signed.result.jsonEncoded);
      this.clientForm.controls['Ds_Signature'].setValue(signed.result.signatureCalculated);

      if (signed.result.result3DS) {

        this.confirmService.confirm({ title: 'Exito', message: "Registro procesado correctamente" })
          .subscribe((result) => {

            if (result) {
              console.log("HI");
            }

            this.cdr.markForCheck();

          });

      } else {

        this.confirmService.confirm({ title: 'ERROR', message: "Registro NO procesado" })
          .subscribe((result) => {

            if (result) {
              console.log("HI");
            }

            this.cdr.markForCheck();
          });

      }

      if (signed.result.merchantUrl != null) {
        this.merchantUrl = signed.result.merchantUrl;
        jQuery('#Ds_MerchantParameters').val(this.jsonEncoded);
        jQuery('#Ds_Signature').val(this.signatureCalculated);
        jQuery('#formid').attr('action', this.merchantUrl).submit();

      }

    }, err => {
      console.log(err);
    });

  }

  onlyNumberKey(event: any) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  onSubmit() {
    const uniqueCode = this.generateUniqueCode();
    this.uniqueCodeService.setUniqueCode(uniqueCode);
    console.log('Código único generado:', uniqueCode);
    console.log('Form data:', this.first.value);

    const apiUrl = "https://centinelapistag.cardinalcommerce.com/V1/Cruise/Collect";
    fetch(apiUrl, {
      method: 'POST',
      body: JSON.stringify(this.first.value)
    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log('Response from server:', responseData);
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
      });
  }

  generateUniqueCode(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

}
