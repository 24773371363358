
<mat-card class="p-0">

    <mat-card-content>
        <form [formGroup]="basicForm" (ngSubmit)="send()">

            <mat-divider></mat-divider>

            <mat-card-title class="">
                <div style="padding-left: 0px;" class="card-title-text">Ingresa tus datos de pago </div>
                <mat-divider></mat-divider>
            </mat-card-title>

        
            <div fxLayout="row wrap">

                <div fxFlex="100" fxFlex.gt-xs="50" class="pr-16">

                    <div class="pb-16">

                        <mat-form-field class="full-width">
                            <input
                            matInput
                            name="nameHolderCreditCard"
                            formControlName="nameHolderCreditCard"
                            placeholder="Titular"
                            value="">
                        </mat-form-field>
                        <small 
                        *ngIf="basicForm.controls['nameHolderCreditCard'].hasError('required') && basicForm.controls['nameHolderCreditCard'].touched" 
                        class="form-error-msg"> Titular es requerido </small>

                    </div>

                    <div class="pb-16">

                        <mat-form-field class="full-width">
                            <mat-label> Año de Expiración </mat-label>
                            <mat-select [formControl]="basicForm.controls['expYearCreditCard']"  >
                                <mat-option value="22">2022</mat-option>
                                <mat-option value="23">2023</mat-option>
                                <mat-option value="24">2024</mat-option>
                                <mat-option value="25">2025</mat-option>
                                <mat-option value="26">2026</mat-option>
                                <mat-option value="27">2027</mat-option>
                                <mat-option value="28">2028</mat-option>
                                <mat-option value="29">2029</mat-option>
                                <mat-option value="30">2030</mat-option>
                                <mat-option value="31">2031</mat-option>
                                <mat-option value="32">2032</mat-option>
                                <mat-option value="33">2033</mat-option>
                                <mat-option value="34">2034</mat-option>
                                <mat-option value="35">2035</mat-option>
                                <mat-option value="36">2036</mat-option>
                                <mat-option value="37">2037</mat-option>
                                <mat-option value="38">2038</mat-option>
                                <mat-option value="39">2039</mat-option>
                                <mat-option value="40">2040</mat-option>
                                <mat-option value="41">2041</mat-option>
                                <mat-option value="42">2042</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <small 
                        *ngIf="basicForm.controls['expYearCreditCard'].hasError('required') && basicForm.controls['expYearCreditCard'].touched" 
                        class="form-error-msg"> Año es requerido </small>

                    </div>


                    <div class="pb-16">
                        <mat-form-field class="full-width">
                        <input 
                            maxlength="3"
                            type="password"
                            name="ccv"
                            matInput
                            formControlName="ccv"
                            (keypress)="onlyNumberKey($event)"
                            placeholder="CCV" 
                            value="">
                        </mat-form-field>
                        <small 
                        *ngIf="basicForm.controls['ccv'].hasError('required') && basicForm.controls['ccv'].touched" 
                        class="form-error-msg"> CCV es requerido </small>
                    </div>

                    <div class="pb-16">
                        <mat-form-field class="full-width">
                        <input 
                            type="email"
                            name="email"
                            matInput
                            formControlName="email"
                            placeholder="Email" 
                            value="">
                        </mat-form-field>
                    </div>


               

                    
                </div>

                <div fxFlex="100" fxFlex.gt-xs="50">
                    <div class="pb-16">
                        <mat-form-field class="full-width">
                            <input 
                            (keypress)="onlyNumberKey($event)"
                            matInput
                            name="numberCreditCard"
                            formControlName="numberCreditCard"
                            placeholder="Tarjeta">
                        </mat-form-field>
                        <small 
                        *ngIf="basicForm.controls['numberCreditCard'].hasError('creditCard') && basicForm.controls['numberCreditCard'].touched" 
                        class="form-error-msg"> Tarjeta es requerido </small>
                    </div>


                    <div class="pb-16">

                        <mat-form-field class="full-width">
                            <mat-label> Mes de Expiración </mat-label>
                            <mat-select [formControl]="basicForm.controls['expMonthCreditCard']"  >
                                <mat-option value="01" >ENERO</mat-option>
                                <mat-option value="02" >FEBRERO</mat-option>
                                <mat-option value="03" >MARZO</mat-option>
                                <mat-option value="04" >ABRIL</mat-option>
                                <mat-option value="05" >MAYO</mat-option>
                                <mat-option value="06" >JUNIO</mat-option>
                                <mat-option value="07" >JULIO</mat-option>
                                <mat-option value="08" >AGOSTO</mat-option>
                                <mat-option value="09" >SEPTIEMBRE</mat-option>
                                <mat-option value="10" >OCTUBRE</mat-option>
                                <mat-option value="11" >NOVIEMBRE</mat-option>
                                <mat-option value="12" >DICIEMBRE</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <small 
                        *ngIf="basicForm.controls['expMonthCreditCard'].hasError('required') && basicForm.controls['expMonthCreditCard'].touched" 
                        class="form-error-msg"> Mes es requerido </small>

                    </div>

                    <div class="pb-16">

                        <mat-form-field class="full-width">
                            <mat-label> Tipo de tarjeta </mat-label>
                            <mat-select [formControl]="basicForm.controls['type']"  >
                                <mat-option value="001">VISA</mat-option>
                                <mat-option value="002">MASTER</mat-option>
                                <mat-option value="003">AMERICAN EXPRESS</mat-option>
                                <mat-option value="004">DISCOVER</mat-option>
                                <mat-option value="005">DINERS CLUB</mat-option>
                                <mat-option value="006">CARTE BLANCHE1</mat-option>
                                <mat-option value="007">JCB1</mat-option>
                                <mat-option value="014">ENROUTE1</mat-option>
                                <mat-option value="021">JAL1</mat-option>
                                <mat-option value="024">MAESTRO (UK DOMESTIC)1</mat-option>
                                <mat-option value="033">VISA ELECTRON1</mat-option>
                                <mat-option value="034">DANKORT1</mat-option>
                                <mat-option value="036">CARTES BANCAIRES1</mat-option>
                                <mat-option value="037">CARTA SI1</mat-option>
                                <mat-option value="039">ENCODED ACCOUNT NUMBER1</mat-option>
                                <mat-option value="040">UATP1</mat-option>
                                <mat-option value="042">MAESTRO (INTERNATIONAL)1</mat-option>
                                <mat-option value="050">HIPERCARD2</mat-option>
                                <mat-option value="051">AURA</mat-option>
                                <mat-option value="054">ELO</mat-option>
                                <mat-option value="062">CHINA UNIONPAY</mat-option>
                                <mat-option value="058">CARNET</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <small 
                        *ngIf="basicForm.controls['type'].hasError('required') && basicForm.controls['type'].touched" 
                        class="form-error-msg"> Tipo es requerido </small>

                    </div>


                </div>
            </div>


            <div fxLayout="row" fxLayoutAlign="end center">
                <button 
                mat-raised-button 
                color="primary" 
                [disabled]="basicForm.invalid ">Suscribirme</button>
            </div>
           
        </form>

    </mat-card-content>
</mat-card>

<!-- <form id="formid" name="form" method="POST" >     
    <input type="hidden" id="Ds_SignatureVersion" name="Ds_SignatureVersion" value="HMAC_SHA256_V1"/>
    <input type="hidden" id="Ds_MerchantParameters" name="Ds_MerchantParameters" value="{{jsonEncoded}}"/>
    <input type="hidden" id="Ds_Signature" name="Ds_Signature" value="{{signatureCalculated}}"/>	
 </form>
<iframe id="cardinal_collection_iframe" name="collectionIframe"  width="500px" height="500px" sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts"> 
    <form  id="aliado-iframe" name='aliado-iframe' method='POST'>
        <input type='hidden' id="valueForm1" name='threeDSMethodData'>
    </form>
</iframe> -->

<!-- <iframe id="cardinal_collection_iframe" name="collectionIframe" height="10" width="10" style="display: none;"></iframe>
<form id="cardinal_collection_form" method="POST" target="collectionIframe" action=https://centinelapistag.cardinalcommerce.com/V1/Cruise/Collect>
<br><br>Input the consumerAuthentificationInformation.accessToken value from step 1, setup here
<input id="cardinal_collection_form_input" type="text" name="JWT" size=100>
<br><br>
<input type="submit" value="Start Device Data Collection">
</form> -->
<!-- 
<iframe name="step-up-iframe" height="900" width="900"></iframe>
<form id="step-up-form" target="step-up-iframe" method="post">
    <input type="hidden" id="JWT2" name="JWT2" /> 
</form>


<form id="aliado-iframe2" name='aliado-iframe2' method='POST'>
    <input type='hidden'  id="valueForm2" name='threeDSMethodData' value='eyJ0aHJlZURTTWV0aG9kTm90aWZpY2F0aW9uVVJMIjoiaHR0cHM6Ly90ZXN0LmVtZXRlYy5wcm8vY29ubmVjdG9ycy9hcl9zaW11bGF0b3IvM2RzMjtqc2Vzc2lvbmlkPUUyN0NEQzY4MTIyRDQyRkEyRTU4MkY5QjQ0NjZEQUQ4LnVhdDAxLXZtLWNvbjAzP2FzeW5jc291cmNlPUFDSV8zRFNfMiZ0eXBlPW1ldGhvZE5vdGlmaWNhdGlvbiZuZGNpZD04YWM3YTRjNzg0NDMwYWMyMDE4NDQzMzQ5ZjEzMDA0NF9mNzlkYzc0NDU5NTM0YWViOWU4MmM3ODljMGU4MjM5OSIsInRocmVlRFNTZXJ2ZXJUcmFuc0lEIjoiNmU4MDVlODctMzYwMC00YWM0LWE4ODgtN2IwZmU0M2UxNmI4In0'>
</form> -->

<!-- <iframe name="ddc-iframe" height="10" width="10" style="display: none;"></iframe>
<form id="ddc-form" target="ddc-iframe" method="POST">
    <input type="hidden" id="JWT" name="JWT" />
</form> -->

<!-- 
<form id="ddc-form" target="ddc-iframe" method="POST" action="https://centinelapistag.cardinalcommerce.com/V1/Cruise/Collect">
       
        <input type="submit" value="submit">
</form> -->
<iframe name="ddc-iframe" height="10" width="10" style="display: none;"></iframe>

  <!-- Botón para continuar con el siguiente paso -->
<!-- <iframe id="cardinal_collection_iframe" name="collectionIframe" height="10" width="10" style="display: none;"></iframe>
<form id="cardinal_collection_form" method="POST" target="collectionIframe" action=https://centinelapistag.cardinalcommerce.com/V1/Cruise/Collect>
<br><br>Input the consumerAuthentificationInformation.accessToken value from step 1, setup here
<input id="cardinal_collection_form_input" type="text" name="JWT" size=100>
<br><br>
<input type="submit" value="Start Device Data Collection">
 
</form> -->

<!-- <html> -->
<!-- HEAD -->
<!-- <head>
<script type="text/javascript" src="https://h.online-metrix.net/fp/tags.js?org_id=1snn5n9w&session_id=merchantID123456789"></script>
</head> -->
<!-- BODY -->
<!-- <body>
<noscript>
<iframe style="width: 100px; height: 100px; border: 0; position:absolute; top: -5000px;" src="https://h.online-metrix.net/fp/tags?org_id=1snn5n9w&session_id=merchantID123456789">/iframe>
</noscript>
</body>
</html> -->
