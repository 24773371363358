import { Component, OnInit } from '@angular/core';
import { UniqueCodeService } from './services/unique-code.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  uniqueCode: string;
  title = 'pw-integration-form';
  iframeSrc: SafeResourceUrl;

  constructor(private uniqueCodeService: UniqueCodeService, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.uniqueCodeService.uniqueCode$.subscribe(code => {
      if (code) {
        this.uniqueCode = code;
        this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://h.online-metrix.net/fp/tags?org_id=1snn5n9w&session_id=bg_avocadoprime' + this.uniqueCode
        );
        console.log('Código único en AppComponent:', this.uniqueCode);
        console.log('iframeSrc:', this.iframeSrc);
      }
    });
  }
}
