import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UniqueCodeService {
  private uniqueCodeSubject = new BehaviorSubject<string>(null);
  uniqueCode$ = this.uniqueCodeSubject.asObservable();

  private transactionIdentifierSubject = new BehaviorSubject<string>(null);
  transactionIdentifier$ = this.transactionIdentifierSubject.asObservable();

  setUniqueCode(code: string): void {
    this.uniqueCodeSubject.next(code);
  }

  getUniqueCode(): string {
    return this.uniqueCodeSubject.value;
  }

  setTransactionIdentifier(identifier: string): void {
    this.transactionIdentifierSubject.next(identifier);
  }

  getTransactionIdentifier(): string {
    return this.transactionIdentifierSubject.value;
  }
}
