import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-validator',
  templateUrl: './validator.component.html',
  styleUrls: ['./validator.component.css']
})
export class ValidatorComponent implements OnInit {
  status: string | null = null;
  transactionId: string | null = null;
  amount: string | null = null;
  currency: string | null = null;
  responseCode: string | null = null;
  responseMessage: string | null = null;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.status = params['status'] || null;
      this.transactionId = params['transactionId'] || null;
      this.amount = params['amount'] || null;
      this.currency = params['currency'] || null;
      this.responseCode = params['responseCode'] || null;
      this.responseMessage = params['responseMessage'] || null;

      if (this.status) {
        this.handleBankResponse(this.status);
      }
    });
  }

  handleBankResponse(status: string): void {
    // Lógica para manejar la respuesta del banco
    console.log('Status recibido del banco:', status);


    if (status === 'success') {
      console.log('Pago exitoso');
    } else {
      console.log('Pago fallido');
    }
  }
}
